import React from 'react'

import Layout from '../../components/Layout'
import ExploreSankey from '../../components/ExploreSankey'

export default function SankeyPage() {
  return (
    <Layout>
      <ExploreSankey />
    </Layout>
  )
}

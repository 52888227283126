import React, { useMemo } from 'react'
import { Group } from '@visx/group'
import 'twin.macro'

import Diagram from './Diagram'

const defaultMargin = { top: 30, left: 30, right: 30, bottom: 30 }

const AGGREGATE_NODE_NAME = 'Others'

/**
 * Create nodes and links with unique id for sankey diagram.
 * @param {D3Hierarchy} root A d3-hierarchy root to create sankey from.
 * @returns {Object} Object with nodes and links for sankey
 */
function getSankeyData(root) {
  // Create list of nodes sorted alphabetically, with condensed node at the end
  const nodes = root
    .descendants()
    .reduce((acc, curr, index) => {
      const indexOfCurr = acc.findIndex(
        (node) => node.name === curr.data.name && node.depth === curr.depth
      )

      if (indexOfCurr === -1) {
        return [
          ...acc,
          {
            name: curr.data.name,
            id: `${curr.data.name}_${curr.depth}`,
            value: curr.value,
            unit: curr.data.unit,
            depth: curr.depth,
            color: curr.data.color,
          },
        ]
      }
      return acc
    }, [])
    .sort((a, b) => {
      if (a.name.includes(AGGREGATE_NODE_NAME)) return 1
      if (b.name.includes(AGGREGATE_NODE_NAME)) return -1
      return b.value - a.value
    })

  const links = root.links().map(({ source, target }) => ({
    source: nodes.find(({ id }) => `${source.data.name}_${source.depth}` === id)
      .id,
    target: nodes.find(({ id }) => `${target.data.name}_${target.depth}` === id)
      .id,
    value: target.value,
  }))

  return {
    nodes,
    links,
  }
}

export default function Sankey({
  parentWidth: width,
  parentHeight: height,
  margin = defaultMargin,
  getRoot,
}) {
  const data = useMemo(() => getSankeyData(getRoot()), [getRoot])

  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  return width < 10 || data == null ? null : (
    <div tw="relative">
      <svg width={width} height={height}>
        <rect width={width} height={height} fill="none" />
        <Group top={margin.top} left={margin.left}>
          <Diagram data={data} width={xMax} height={yMax} />
        </Group>
      </svg>
    </div>
  )
}

import React, { useMemo } from 'react'
import { sankey, sankeyJustify, sankeyLinkHorizontal } from 'd3-sankey'
import { interpolateCool } from 'd3-scale-chromatic'
import cloneDeep from 'lodash/cloneDeep'

import { getSubtitle, getRoundedString } from '../../util'
import { getPrecision } from '../../util/charts'

const RECT_WIDTH = 30
const RECT_PADDING = 15
const LABEL_OFFSET = 8
const colors = interpolateCool

const Rect = ({ x0, x1, y0, y1, name, value, unit, color, width }) => {
  const textStyle = {
    alignmentBaseline: 'middle',
    fontSize: 12,
    textAnchor: x0 < width / 2 ? 'start' : 'end',
    pointerEvents: 'none',
    userSelect: 'none',
  }
  const textHeight = y1 - y0
  const centerOfRect = (y1 + y0) / 2

  const hasSpaceForValue =
    centerOfRect - LABEL_OFFSET > y0 && centerOfRect + LABEL_OFFSET < y1

  return (
    <g data-test-id={name}>
      <rect x={x0} y={y0} width={x1 - x0} height={textHeight} fill={color} />
      <text
        x={x0 < width / 2 ? x1 + 6 : x0 - 6}
        y={hasSpaceForValue ? centerOfRect - LABEL_OFFSET : centerOfRect}
        style={textStyle}
      >
        {name}
      </text>
      {hasSpaceForValue && (
        <text
          x={x0 < width / 2 ? x1 + 6 : x0 - 6}
          y={centerOfRect + LABEL_OFFSET}
          style={textStyle}
        >
          {getSubtitle(getRoundedString(value, getPrecision(value)), unit)}
        </text>
      )}
    </g>
  )
}

const Link = ({ data, width }) => {
  const link = sankeyLinkHorizontal()

  return (
    <>
      <path
        d={link(data)}
        fill="none"
        stroke="black"
        strokeOpacity={0.1}
        strokeWidth={width}
      />
    </>
  )
}

export default function Diagram({ data, width, height }) {
  if (data.links.length) {
    return <DiagramWithData data={data} width={width} height={height} />
  }

  const node = data.nodes[0]

  return (
    <Rect
      x0={width / 2 - RECT_WIDTH}
      x1={width / 2 + RECT_WIDTH}
      y0={0}
      y1={height}
      name={node.name}
      value={node.value}
      unit={node.unit}
      color={colors(0)}
      width={width}
    />
  )
}

function DiagramWithData({ data, width, height }) {
  const { links, nodes } = useMemo(() => {
    const sankeyDiagram = sankey()
      .nodeId((d) => d.id)
      .nodeAlign(sankeyJustify)
      .nodeWidth(RECT_WIDTH)
      .nodePadding(RECT_PADDING)
      .nodeSort(null)
      .extent([
        [0, 0],
        [width, height],
      ])
    const clonedData = cloneDeep(data)
    return sankeyDiagram(clonedData)
  }, [data, width, height])

  const unit = nodes[0].unit

  // Add color without skipping colors in interpolator
  const colorNodes = useMemo(
    () => [
      ...nodes.filter((d) => d.color),
      ...nodes
        .filter((d) => !d.color)
        .map((d, i, arr) => ({
          ...d,
          color: colors(i / arr.length),
        })),
    ],
    [nodes]
  )

  // TODO: How to handle when diagram won't render properly due to lack of space? Reduce maxChildrenLength on parent component?
  if (nodes[nodes.length - 1].y0 === nodes[nodes.length - 1].y1) {
    return <text fontStyle="italic">Not enough height to display diagram.</text>
  }

  return (
    <>
      <g>
        {links.map((d) => (
          <Link
            key={`sankey-link-${d.index}`}
            data={d}
            width={d.width}
            length={nodes.length}
          />
        ))}
      </g>
      <g>
        {colorNodes.map((d) => (
          <Rect
            key={`sankey-node-${d.id}`}
            x0={d.x0}
            x1={d.x1}
            y0={d.y0}
            y1={d.y1}
            name={d.name}
            value={d.value}
            unit={unit}
            color={d.color}
            width={width}
          />
        ))}
      </g>
    </>
  )
}

import { useMeasure } from 'react-use'
import tw, { theme } from 'twin.macro'

import ChartConfigSection from './ChartConfigSection'
import Sankey from './Sankey'
import useGetRoot from '../connectors/aggregateDataContext/useGetRoot'
import { HaulOptions, LocationOptions, TimeOptions } from './CustomOptions'

const filterDefinitions = [
  { name: 'Haul', key: 'haul', cat: 'travel', options: <HaulOptions /> },
  { name: 'Time', key: 'time', cat: 'travel', options: <TimeOptions /> },
  { name: 'Booking Class', key: 'cls', cat: 'travel' },
  {
    name: 'Destination',
    key: 'location',
    cat: 'travel',
    options: <LocationOptions />,
  },
  { name: 'Reason', key: 'flight_reason', cat: 'travel' },
  { name: 'Duration', key: 'duration', cat: 'travel' },
  { name: 'Age', key: 'age', cat: 'demographics' },
  { name: 'Gender', key: 'gender', cat: 'demographics' },
  { name: 'Role', key: 'role', cat: 'demographics' },
  { name: 'Manager', key: 'manager', cat: 'demographics' },
  { name: 'Org Code', key: 'org_code', cat: 'demographics' },
]
export default function ExploreSankey() {
  const { getRoot } = useGetRoot(filterDefinitions)

  const [ref, { width, height }] = useMeasure()

  return (
    <div tw="flex-1 flex flex-col lg:items-center justify-center lg:flex-row">
      <div
        css={[
          `height: calc(100vh - ${theme`spacing.20`});`,
          tw`w-full flex flex-col mx-auto lg:order-last overflow-x-auto`,
        ]}
        ref={ref}
      >
        {width && (
          <Sankey parentWidth={width} parentHeight={height} getRoot={getRoot} />
        )}
      </div>
      <ChartConfigSection
        filterDefinitions={filterDefinitions}
        disableFilters
        shouldHidePerFte
        tw="mt-4 lg:mt-0"
      />
    </div>
  )
}
